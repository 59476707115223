import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { useDataLayerBuilder } from '@tymate/react-gtm';
import ArticleHeader from '../components/Article/ArticleHeader';
import { API_URL } from '../constants';
import ArticleAuthor from '../components/Article/ArticleAuthor';
import ArticleContent from '../components/Article/ArticleContent';
import ArticleComments from '../components/Article/ArticleComments';

const Article = ({ data: { blog }, location }) => {
  useDataLayerBuilder(() => ({
    pageType: 'blog',
  }));

  const picture = blog.picture && blog.picture.imagePath;

  return (
    <Layout>
      <SEO title={blog.displayName} />

      <ArticleHeader
        title={blog.displayName}
        thematic={blog.thematic.displayName}
        picture={API_URL + '/' + picture}
      />

      <div className="mt-32 max-w-7xl mx-auto px-16">
        <div className="md:grid grid-cols-3 -md:space-y-16">
          <div className="relative">
            <div className="sticky top-36">
              <ArticleAuthor
                author={blog.authorable ? `${blog.authorable.firstName} ${blog.authorable.lastName}` : 'Feelingo'}
                created={blog.createdAt}
              />
            </div>
          </div>

          <div className="col-span-2">
            <ArticleContent content={blog.description}/>

            <div className="py-32">
              <ArticleComments/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($language: String!, $id: String!) {
    blog(language: { eq: $language }, id: { eq: $id }) {
      id
      description
      displayName
      picture {
        imagePath
      }
      thematic {
        displayName
      }
      authorable {
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export default Article;
