import React, { useContext } from 'react';
import { Link } from 'gatsby';
import PageContext from '../../contexts/page';
import { useTranslation } from 'react-i18next';

export default function ArticleHeader({ title, thematic, picture }) {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);

  return (
    <div className="tw">
      <div className="px-8 py-12">
        <div className="max-w-lg mx-auto">
          <Link
            className="text-brand-green text-xs font-bold uppercase"
            to={getLanguagePrefix(t('url.articles'))}
          >
            &larr; {t('articles.goBack')}
          </Link>

          <div className="my-4 text-4xl font-bold">{title}</div>

          <div className="inline-block bg-brand-grey px-4 py-2 rounded-3xl">
            <div className="text-gray-500 text-xs font-bold uppercase">
              {thematic}
            </div>
          </div>
        </div>
      </div>

      <div className="px-16">
        <div style={{
          height: '541px',
          borderRadius: '50px',
          backgroundImage: `url(${picture})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}/>
      </div>
    </div>
  )
}
