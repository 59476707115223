import React from 'react';
import ArticleLikeButton from './ArticleLikeButton';
import ArticleShareButtons from './ArticleShareButtons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function ArticleAuthor({ author, created }) {
  const { t } = useTranslation();

  return (
    <div className="md:w-64 md:pt-5">
      <div className="mb-2">
        <div className="mb-1">{t('articles.by')} {author}</div>
        <div className="text-sm text-gray-500">{moment(created).format('LL')}</div>
      </div>

      <ArticleLikeButton/>

      <div className="mt-10">
        <ArticleShareButtons/>
      </div>
    </div>
  )
}
